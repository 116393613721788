<template>
  <div class="timeline__decade">
    <svg width="24" height="100" viewBox="0 0 24 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="14" y="10.9092" width="10" height="1.81818" fill="#38383A"/>
      <rect x="14" y="65.4546" width="10" height="1.81818" fill="#38383A"/>
      <rect x="14" y="21.8184" width="10" height="1.81818" fill="#38383A"/>
      <rect x="14" y="76.3638" width="10" height="1.81818" fill="#38383A"/>
      <rect x="14" y="32.7271" width="10" height="1.81818" fill="#38383A"/>
      <rect x="14" y="87.2729" width="10" height="1.81818" fill="#38383A"/>
      <rect x="14" y="43.6362" width="10" height="1.81818" fill="#38383A"/>
      <rect x="14" y="98.1816" width="10" height="1.81818" fill="#38383A"/>
      <rect x="10" y="54.5454" width="14" height="1.81818" fill="#38383A"/>
      <rect width="24" height="1.81818" fill="#38383A"/>
    </svg>

    <p class="timeline__decade__start-year">
      {{ startYear }}
    </p>

    <span v-for="imageYear in imagesYears"
          :key="imageYear"
          class="timeline__decade__image-year"
          :style="{
            bottom: `${ (endYear - imageYear) * 10 }%`
          }"
    >
      {{ imageYear }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TimelineDecade",
  props: {
    decade: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('Coordinates', ['getPeriodImagesYears']),

    startYear() {
      return this.decade.startYear
    },
    endYear() {
      return this.decade.endYear
    },
    imagesYears() {
      return this.getPeriodImagesYears(this.startYear, this.endYear)
    }
  }
}
</script>

<style lang="scss">
.timeline__decade {
  position: relative;
  padding-bottom: 4px;

  &__start-year {
    position: absolute;
    right: 30px;
    font-size: 1.3em;
    top: 0;
    transform: translateY(-50%);
  }

  &__image-year {
    position: absolute;
    left: 30px;
    color: $app-primary-color;
    font-size: .85em;
    transform: translateY(-2px);
  }

}

@media screen and (max-width: 1300px) {
  .timeline__decade {
    font-size: .8em;
    padding-bottom: 5px;
  }
}
</style>