<template>
  <header class="header">
    <div class="logo">
      <img src="@/assets/images/Logo.svg"
           alt="logo"
           class="logo__image"
      >
    </div>

    <div class="header__filters"
         :class="{'header__filters--mobile-active': showDropdown}"
    >
      <epoch-selector/>
      <tags-selector/>
      <search-field/>

      <div>
        <div v-if="isLoading"
             class="loader"
        />

        <button v-if="hasFilters && !isLoading"
                type="button"
                class="header__filters__clear"
                @click="clearFilters"
        >
          <img src="@/assets/images/icons/cross-primary.svg"
               alt="cross icon"
          >
          <span>Clear all</span>
        </button>
      </div>
    </div>

    <nav class="header__nav">
      <ul>
        <li>
          <router-link :to="{ name: 'gallery' }">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 32" width="26" height="32">
              <path fill="none"
                    stroke="currentColor"
                    stroke-linejoin="miter"
                    stroke-linecap="butt"
                    stroke-miterlimit="4"
                    stroke-width="4.1739"
                    d="M2.087 2.087h8.348v11.13h-8.348v-11.13z"
              />
              <path fill="none"
                    stroke="currentColor"
                    stroke-linejoin="miter"
                    stroke-linecap="butt"
                    stroke-miterlimit="4"
                    stroke-width="4.1739"
                    d="M2.087 18.783h8.348v11.13h-8.348v-11.13z"
              />
              <path fill="none"
                    stroke="currentColor"
                    stroke-linejoin="miter"
                    stroke-linecap="butt"
                    stroke-miterlimit="4"
                    stroke-width="4.1739"
                    d="M16 2.087h8.348v11.13h-8.348v-11.13z"
              />
              <path fill="none"
                    stroke="currentColor"
                    stroke-linejoin="miter"
                    stroke-linecap="butt"
                    stroke-miterlimit="4"
                    stroke-width="4.1739"
                    d="M16 18.783h8.348v11.13h-8.348v-11.13z"
              />
            </svg>
            Galerie
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'home' }">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 32" width="26" height="32">
              <path fill="none"
                    stroke="currentColor"
                    stroke-linejoin="miter"
                    stroke-linecap="butt"
                    stroke-miterlimit="4"
                    stroke-width="3.7795"
                    d="M1.89 4.032l7.559 6.299v17.638l-7.559-6.299v-17.638z"
              />
              <path fill="none"
                    stroke="currentColor"
                    stroke-linejoin="miter"
                    stroke-linecap="butt"
                    stroke-miterlimit="4"
                    stroke-width="3.7795"
                    d="M9.449 29.228l7.559-6.299v-17.638l-7.559 6.299"
              />
              <path fill="none"
                    stroke="currentColor"
                    stroke-linejoin="miter"
                    stroke-linecap="butt"
                    stroke-miterlimit="4"
                    stroke-width="3.7795"
                    d="M17.008 4.032l7.559 6.299v17.638l-7.559-6.299"
              />
            </svg>
            Karte
          </router-link>
        </li>
      </ul>
    </nav>

    <button class="header__burger"
            :class="{'header__burger--active': showDropdown}"
            @click="showDropdown = !showDropdown"
    >
      <div/>
      <div/>
      <div/>
    </button>
  </header>
</template>

<script>
import EpochSelector from "@/components/common/TheHeader/EpochSelector/EpochSelector";
import TagsSelector from "@/components/common/TheHeader/TagsSelector/TagsSelector";
import SearchField from "@/components/common/TheHeader/SearchField/SearchField";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "TheHeader",
  components: {
    EpochSelector,
    TagsSelector,
    SearchField
  },
  data() {
    return {
      showDropdown: false
    }
  },
  computed: {
    ...mapState('Coordinates', ['isLoading']),
    ...mapGetters('Filters', ['hasFilters'])
  },
  methods: {
    clearFilters() {
      this.resetAllFilters()
      this.reloadCoordinates()
    },

    ...mapActions('Coordinates', ['reloadCoordinates']),
    ...mapActions('Filters', ['resetAllFilters'])
  }
}
</script>

<style lang="scss">
.header {
  position: relative;
  height: $header-height;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 4px rgba(0,0,0, 0.5);
  background-color: $app-block-color;
  z-index: 50;

  &__burger {
    display: none;
    position: relative;
    width: 40px;
    height: 30px;
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;

    div {
      position: absolute;
      left: 0;
      width: 100%;
      height: 6px;
      background-color: $app-primary-color;
      transition: .3s;

      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: calc(50% - 3px);
      }
      &:nth-child(3) {
        top: calc(100% - 6px);
      }
    }
    
    &--active {
      div {
        top: calc(50% - 3px) !important;
      }
      div:nth-child(1) {
        transform: rotate(-45deg);
      }
      div:nth-child(2) {
        opacity: 0;
      }
      div:nth-child(3) {
        transform: rotate(45deg);
      }
    }

  }

  .logo {
    height: 100%;
    margin-right: 140px;

    &__image {
      height: 100%;
    }

  }

  &__filters {
    display: flex;
    align-items: center;

    .loader {
      margin-left: 10px;
    }

    &__clear {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 1em;
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: $app-primary-color;

      img {
        width: 13px;
        height: 13px;
        margin-right: 5px;
      }

      span {
        margin-top: 3px;
      }

    }

  }

  &__nav {
    margin-left: auto;
    
    ul {
      display: flex;

      li {
        font-size: 1.8em;
        font-weight: 500;
        text-transform: uppercase;
        margin-left: 20px;
        white-space: nowrap;

        a {
          position: relative;
          transition: color .3s;
          padding: 10px 0;

          &:after {
            content: "";
            display: block;
            width: 100%;
            height: 3px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba($app-primary-accent-color, 0);
            transition: background-color .3s;
          }

          &.router-link-exact-active {
            color: $app-primary-accent-color;

            &:after {
              background-color: $app-primary-accent-color;
            }

          }

        }

        svg {
          width: .8em;
          height: .8em;
        }

      }

    }
    
  }

}

@media screen and (max-width: 1500px) {
  .header {
    .logo {
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .header {
    .logo {
      width: 140px;
      margin-right: 20px;
    }

    &__nav {
      ul {
        li {
          font-size: 1.4em;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .header {
    &__burger {
      display: block;
    }
    &__filters {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 100px;
      visibility: hidden;
      position: absolute;
      left: 0;
      width: 100%;
      top: calc($header-height * 0.75);
      background-color: $app-block-color;
      padding: 20px;
      opacity: 0;
      transition: .3s;
      z-index: -1;

      &--mobile-active {
        opacity: 1;
        visibility: visible;
        top: $header-height;
      }
    }
    &__nav {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    &__filters {
      grid-template-columns: 100%;
      grid-row-gap: 20px;
    }
  }
}
</style>
