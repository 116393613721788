/* eslint-disable no-unused-vars */
import getQueryParams from "@/helpers/getQueryParams";
import router from "@/router";

export default function setPageQueryParams(params) {
  let filters = {
    'builders[]': params.builders,
    'epochs[]': params.epochs,
    'regions[]': params.regions,
    'streets[]': params.streets,
    'types[]': params.types
  }

  if (params.q) {
    Object.assign(filters, { q: params.q })
  }

  Object.keys(filters)
    .forEach((filter) => {
      if (filters[filter]?.[0] === undefined) {
        delete filters[filter]
      } else if (typeof filters[filter] === 'object' && Array.isArray(filters[filter])) {
        filters[filter] = filters[filter].map((id) => Number(id))
      }
    })

  if (Object.values(filters).length === 0) {
    let url = window.location.origin + window.location.pathname
    window.history.pushState({ path: url }, '', url)
  } else {
    router.push({ query: filters })
  }
}