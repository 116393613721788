<template>
  <router-link :to="{ name: linkTo }"
               class="mobile-navigator"
  >
    <svg v-if="linkTo === 'gallery'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 32" width="26" height="32">
      <path fill="none"
            stroke="currentColor"
            stroke-linejoin="miter"
            stroke-linecap="butt"
            stroke-miterlimit="4"
            stroke-width="4.1739"
            d="M2.087 2.087h8.348v11.13h-8.348v-11.13z"
      />
      <path fill="none"
            stroke="currentColor"
            stroke-linejoin="miter"
            stroke-linecap="butt"
            stroke-miterlimit="4"
            stroke-width="4.1739"
            d="M2.087 18.783h8.348v11.13h-8.348v-11.13z"
      />
      <path fill="none"
            stroke="currentColor"
            stroke-linejoin="miter"
            stroke-linecap="butt"
            stroke-miterlimit="4"
            stroke-width="4.1739"
            d="M16 2.087h8.348v11.13h-8.348v-11.13z"
      />
      <path fill="none"
            stroke="currentColor"
            stroke-linejoin="miter"
            stroke-linecap="butt"
            stroke-miterlimit="4"
            stroke-width="4.1739"
            d="M16 18.783h8.348v11.13h-8.348v-11.13z"
      />
    </svg>
    <svg v-else-if="linkTo === 'home'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 32" width="26" height="32">
      <path fill="none"
            stroke="currentColor"
            stroke-linejoin="miter"
            stroke-linecap="butt"
            stroke-miterlimit="4"
            stroke-width="3.7795"
            d="M1.89 4.032l7.559 6.299v17.638l-7.559-6.299v-17.638z"
      />
      <path fill="none"
            stroke="currentColor"
            stroke-linejoin="miter"
            stroke-linecap="butt"
            stroke-miterlimit="4"
            stroke-width="3.7795"
            d="M9.449 29.228l7.559-6.299v-17.638l-7.559 6.299"
      />
      <path fill="none"
            stroke="currentColor"
            stroke-linejoin="miter"
            stroke-linecap="butt"
            stroke-miterlimit="4"
            stroke-width="3.7795"
            d="M17.008 4.032l7.559 6.299v17.638l-7.559-6.299"
      />
    </svg>
  </router-link>
</template>

<script>
export default {
  name: "MobileNavigator",
  computed: {
    linkTo() {
      return this.$route.name === 'home' ? 'gallery' : 'home'
    }
  }
}
</script>

<style lang="scss">
.mobile-navigator {
  display: none;
}

@media screen and (max-width: 1024px) {
  .mobile-navigator {
    position: fixed;
    bottom: 20px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 66px;
    height: 66px;
    z-index: 20;
    background-color: $app-block-color;
    box-shadow: 0 2px 4px $app-box-shadow-color;
  }
}
</style>
