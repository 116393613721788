<template>
  <div class="selector__controls">
    <button class="selector__control"
            @click="clearFilters"
    >
      <img src="@/assets/images/icons/cross-primary.svg"
           alt="cross icon"
      >
      Clear
    </button>

    <button class="selector__control"
            @click="applyFilters"
    >
      Apply
    </button>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "SelectorControls",
  methods: {
    clearFilters() {
      this.resetAllFilters()
      this.reloadCoordinates()
      this.$emit('clear-filters')
      this.$emit('action')
    },
    applyFilters() {
      this.reloadCoordinates()
      this.$emit('apply-filters')
      this.$emit('action')
    },

    ...mapActions('Coordinates', ['initCoordinates', 'reloadCoordinates']),
    ...mapActions('Filters', ['resetAllFilters'])
  }
}
</script>