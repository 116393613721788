<template>
  <div class="timeline-images hide-scrollbar">
    <item-gallery-list-item v-for="image in images"
                            :key="image.id"
                            :image="image"
    />
  </div>
</template>

<script>
import ItemGalleryListItem from "@/components/pages/ItemGallery/ItemGalleryContent/ItemGalleryListItem";
import { mapGetters } from "vuex";

export default {
  name: "TimelineImages",
  components: {
    ItemGalleryListItem
  },
  computed: {
    ...mapGetters('Coordinates', ['images'])
  }
}
</script>

<style lang="scss">
.timeline-images {
  display: none;
  overflow: auto;
}

@media screen and (max-width: 1024px) {
  .timeline-images {
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: 20px;
  }
}
</style>