<template>
  <div class="tags-selector__list">
    <p class="tags-selector__list__title">{{ title }}</p>
    <tags-selector-item v-for="filter in filters"
                        :key="filter.id"
                        :filter="filter"
                        :filter-type="filterType"
    />
  </div>
</template>

<script>
import TagsSelectorItem from "@/components/common/TheHeader/TagsSelector/TagsSelectorItem";

export default {
  name: "TagsSelectorList",
  components: {
    TagsSelectorItem
  },
  props: {
    title: {
      type: String,
      required: true
    },
    filters: {
      type: Array,
      required: true
    },
    filterType: {
      type: String,
      required: true,
      validator: (v) => ['builders', 'epochs', 'regions', 'streets', 'types'].includes(v)
    }
  }
}
</script>