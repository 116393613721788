<template>
  <div class="app-dropdown"
       :class="{'active': show}"
       v-click-outside="hideDropdown"
  >
    <div class="app-dropdown__label"
         tabindex="0"
         @click="toggleDropdown"
    >
      <p class="app-dropdown__label__title">{{ title }}</p>

      <img src="@/assets/images/icons/arrow-down-triangle_green.svg"
           alt="arrow down"
           class="app-dropdown__label__arrow-down"
      >
    </div>

    <transition name="fade">
      <div v-if="show"
           class="app-dropdown__dropdown"
      >
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "AppDropdown",
  props: {
    title: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    toggleDropdown() {
      this.show = !this.show
    },
    hideDropdown() {
      this.show = false
    }
  }
}
</script>

<style lang="scss">
.app-dropdown {
  position: relative;
  margin: 0 9px;

  &__label {
    position: relative;
    height: 58px;
    width: 280px;
    background-color: $dropdown-background;
    padding: 10px;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      left: $dropdown-separator-paddings;
      right: $dropdown-separator-paddings;
      bottom: 0;
      height: 1px;
      background-color: rgba($dropdown-separator-background-color, 0);
      transition: background-color .3s;
    }

    &__title {
      font-family: "DIN Condensed", sans-serif;
      font-size: 2.5em;
      font-weight: 700;
      color: $dropdown-title-color;
      transition: color .3s;
    }

    &__arrow-down {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

  }

  &__dropdown {
    position: absolute;
    top: 100%;
    min-width: 100%;
    background-color: $dropdown-background;
  }

  .active &__label {

    &__title {
      color: $dropdown-title-color_active;
    }

    &:after {
      background-color: $dropdown-separator-background-color;
    }

  }

}

@media screen and (max-width: 1400px) {
  .app-dropdown {
    &__label {
      width: 200px;

      &__title {
        font-size: 2em;
        line-height: 1.5em;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .app-dropdown {
    &__label {
      width: 100%;
    }
  }
}
</style>
