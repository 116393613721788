<template>
  <transition name="fade">
    <div v-if="show"
         class="fullscreen-view"
         @click="close"
    >
      <div class="fullscreen-view__container">
        <img :src="image.src + '&size=xl'"
             alt="image"
             class="fullscreen-view__img"
             @click.stop
        >

        <button class="btn btn-icon fullscreen-view__close"
                @click="close"
        >
          <img src="@/assets/images/icons/close.svg"
               alt="close icon"
          >
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "FullscreenImage",
  computed: {
    ...mapState('Coordinates', {
      'imageId': 'fullscreenImageId'
    }),
    ...mapGetters('Coordinates', ['getImageById']),

    image() {
      return this.imageId ? this.getImageById(this.imageId) : null
    },
    show() {
      return this.image !== null
    }
  },
  methods: {
    close() {
      this.setFullscreenImageId(null)
    },

    ...mapActions('Coordinates', ['setFullscreenImageId'])
  }
}
</script>

<style lang="scss">
.fullscreen-view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, .92);
  z-index: 100;
  padding: 10vh 20vw;

  &__container {
    position: relative;
  }

  &__img {
    max-height: 90vh;
    max-width: 80vw;
  }

  &__close {
    position: absolute;
    top: 0;
    right: -60px;
    transform: translateX(100%);
  }

}
</style>