import axios from "axios";
import endpoints from "@/api/endpoints";
import getQueryParams from "@/helpers/getQueryParams";

class ApiClient {
  axiosInstance

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_API_ENDPOINT
    })
  }

  getAllCoordinates() {
    return this.axiosInstance.get(endpoints.GET_ALL_COORDINATES)
  }

  getCoordinate(coordinateId) {
    return this.axiosInstance.get(endpoints.GET_COORDINATE(coordinateId))
  }

  getCoordinateImage(coordinateId) {
    return this.axiosInstance.get(endpoints.GET_COORDINATE_IMAGE(coordinateId))
  }

  getImage(imageId) {
    return this.axiosInstance.get(endpoints.GET_IMAGE(imageId))
  }

  getImageBySrcNumber(srcNumber) {
    return this.axiosInstance.get(endpoints.GET_IMAGE_BY_SRC_NUMBER(srcNumber))
  }

  getByFilter(filters = {}) {
    return this.axiosInstance.get(endpoints.FILTER + getQueryParams(filters))
  }

  getTags() {
    return this.axiosInstance.get(endpoints.GET_TAGS)
  }

}

export default new ApiClient()
