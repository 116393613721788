export default function getDecades(minYear, maxYear) {
  let result = []

  let startDecade = minYear / 10
  let endDecade = maxYear / 10

  for (let i = startDecade; i < endDecade; i++) {
    result.push({
      decade: i,
      startYear: i * 10,
      endYear: (i * 10) + 9
    })
  }

  return result
}