import Vue from 'vue';
import App from './App.vue';

import store from "@/store";
import router from "@/router";

import api from "@/api";

import VClickOutside from "v-click-outside";

import 'mapbox-gl/src/css/mapbox-gl.css';
import '@/assets/styles/index.scss';

Vue.prototype.$api = api

Vue.use(VClickOutside)

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
