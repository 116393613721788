<template>
  <label class="app-tag"
         :class="{'app-tag--disabled': disabled}"
  >
    <input v-model="model"
           type="checkbox"
           :disabled="disabled"
           class="app-tag__input"
    >
    <span class="app-tag__label">
      <slot></slot>
    </span>
  </label>
</template>

<script>
export default {
  name: "AppTag",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss">
.app-tag {
  margin-right: 5px;
  line-height: 180%;
  cursor: pointer;
  
  &--disabled {
    opacity: .5;
    cursor: default;
  }

  &__input {
    appearance: none;
    position: absolute;
    z-index: -10;
  }

  &__label {
    padding: 1px 7px;
    background-color: $app-block-color;
    border: 1px solid $app-primary-color;
    border-radius: 11px;
    color: $app-primary-color;
    transition: .3s;
    white-space: nowrap;
  }

  &:not(.app-tag--disabled) &__label:hover {
    color: white;
    background-color: $app-primary-accent-color;
    border-color: $app-primary-accent-color;
  }

  &__input:checked + &__label {
    background-color: $app-primary-color;
    color: white;
  }

}
</style>
