<template>
  <label class="app-checkbox"
         :class="{'app-checkbox--disabled': disabled}"
  >
    <input v-model="model"
           type="checkbox"
           :disabled="disabled"
           class="app-checkbox__input"
    >

    <span class="app-checkbox__box"></span>

    <span class="app-checkbox__label">
      <slot></slot>
    </span>
  </label>
</template>

<script>
export default {
  name: "AppCheckbox",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss">
.app-checkbox {
  cursor: pointer;
  transition: background-color .2s;

  &:not(.app-checkbox--disabled):hover {
    background-color: darken($dropdown-background, 10%);
  }

  &--disabled {
    opacity: .5;
    cursor: default;
  }

  &__input {
    appearance: none;
    position: absolute;
    z-index: -10;
  }

  &__box {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 1px solid $app-primary-color;
    background-color: $app-block-color;

    &:after {
      content: "";
      position: absolute;
      top: 40%;
      left: 50%;
      display: block;
      width: 7px;
      height: 12px;
      border-bottom: 4px solid $app-primary-color;
      border-right: 4px solid $app-primary-color;
      transform: translate(-50%, -50%) rotate(45deg) scale(.8);
      opacity: 0;
      transition: opacity .3s;
    }

  }

  &__input:checked + &__box:after {
    opacity: 1;
  }

}
</style>