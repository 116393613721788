/**
 * getQueryParams
 *
 * @param {Object} data
 */
export default function getQueryParams(data) {
  let params = []

  for (let key in data) {
    if (typeof data === 'object' && Array.isArray(data[key])) {
      data[key].forEach((value) => {
        if (value) params.push(`${ key }[]=${ value }`)
      })
    } else {
      if (data[key]) params.push(`${ key }=${ data[key] }`)
    }
  }

  return params.length ? `?${ params.join('&') }` : ''
}
