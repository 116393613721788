const Home = () => import('@/views/Home');
const Gallery = () => import('@/views/Gallery')
const ItemGallery = () => import('@/views/ItemGallery')
const Details = () => import('@/views/Details')

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: Gallery
  },
  {
    path: '/gallery/:itemId',
    name: 'itemGallery',
    component: ItemGallery
  },
  {
    path: '/details/:imageId',
    name: 'details',
    component: Details
  },

  {
    path: '*',
    redirect: '/'
  }
]

export default routes
