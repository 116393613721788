<template>
  <app-tag v-model="value"
           :disabled="filter.disabled"
  >
    {{ filter.name }}
  </app-tag>
</template>

<script>
import filterMixin from "@/mixins/filter";
import AppTag from "@/components/common/AppTag/AppTag";

export default {
  name: "TagsSelectorItem",
  mixins: [filterMixin],
  components: {
    AppTag
  }
}
</script>