import api from "@/api";
import Coordinate from "@/models/Coordinate";
import Image from "@/models/Image";
import setPageQueryParams from "@/helpers/setPageQueryParams";

const state = {
  isLoading: true,
  coordinates: null,
  activeCoordinateId: null,
  fullscreenImageId: null
}

const getters = {
  isCoordinatesLoaded: (state) => state.coordinates !== null,
  coordinates: (state) => state.coordinates || [],
  getCoordinateById: (state, getters) => (coordinateId) => getters.coordinates.find(({ id }) => id === coordinateId) || null,

  images: (state, getters) => {
    return [].concat.apply([], getters.coordinates.map(({ images }) => images))
  },
  getImageById: (state, getters) => (imageId) => getters.images.find(({ id }) => id === imageId) || null,
  imagesYears: (state, getters) => {
    return [...new Set([].concat.apply([], getters.images.map(({ yearOfBuild, yearOfRebuild }) => [yearOfBuild, yearOfRebuild]))
      .filter((year) => year !== 0))]
  },
  getPeriodImagesYears: (state, getters) => (startYear, endYear) => {
    return getters.imagesYears.filter((year) => year >= startYear && year <= endYear)
  }
}

const mutations = {
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_COORDINATES(state, coordinates) {
    state.coordinates = coordinates
  },
  SET_IMAGE_INFO(state, image) {
    let coordinateIndex = state.coordinates.findIndex(({ id }) => id === image.coordinateId)

    if (~coordinateIndex) {
      state.coordinates[coordinateIndex].images = [
        ...state.coordinates[coordinateIndex].images.filter(({ id }) => id !== image.id),
        image
      ].sort((a, b) => a.id - b.id)
    }
  },
  SET_ACTIVE_COORDINATE_ID(state, coordinateId) {
    state.activeCoordinateId = coordinateId
  },
  SET_FULLSCREEN_IMAGE_ID(state, imageId) {
    state.fullscreenImageId = imageId
  }
}

const actions = {
  initCoordinates({ dispatch, commit }) {
    commit('SET_IS_LOADING', true)
    return api.getByFilter()
      .then(({ data: { data } }) => {
        dispatch('Filters/initFilters', data.tags, { root: true })
        let coordinates = data.coordinates.map((coordinate) => new Coordinate(coordinate))
        dispatch('setCoordinates', coordinates)
        dispatch('Filters/processRouteFilters', undefined, { root: true })
        return coordinates
      })
      .finally(() => {
        commit('SET_IS_LOADING', false)
      })
  },
  reloadCoordinates({ rootGetters, dispatch, commit }) {
    commit('SET_IS_LOADING', true)
    const filters = rootGetters['Filters/apiActiveFilters']
    return api.getByFilter(filters)
      .then(({ data: { data } }) => {
        let coordinates = data.coordinates.map((coordinate) => new Coordinate(coordinate))
        dispatch('Filters/processActiveFiltersData', data.tags, { root: true })
        commit('SET_COORDINATES', coordinates)
        setPageQueryParams(filters)
        return coordinates
      })
      .finally(() => {
        commit('SET_IS_LOADING', false)
      })
  },
  getImageInfo({ commit }, imageId) {
    commit('SET_IS_LOADING', true)
    return api.getImage(imageId)
      .then(({ data: { data } }) => {
        let image = new Image(data, data.coordinate.id)
        commit('SET_IMAGE_INFO', image)
        return image
      })
      .finally(() => {
        commit('SET_IS_LOADING', false)
      })
  },
  setCoordinates({ dispatch, commit }, coordinates) {
    dispatch('setActiveCoordinateId', null)
    commit('SET_COORDINATES', coordinates)
  },
  setActiveCoordinateId({ commit }, coordinateId) {
    commit('SET_ACTIVE_COORDINATE_ID', coordinateId)
  },
  setFullscreenImageId({ commit }, imageId) {
    commit('SET_FULLSCREEN_IMAGE_ID', imageId)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
