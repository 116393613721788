<template>
  <div class="map-container">
    <mgl-map :access-token="mapConfig.accessToken"
             :map-style="mapConfig.style"
             :center="center"
             :zoom="mapConfig.initialZoom"
             class="map"
             @load="onMapLoad"
             @click="removeActiveCoordinate"
    >
      <mgl-marker v-for="coordinate in coordinates"
                  :key="coordinate.id"
                  :coordinates="[coordinate.lng, coordinate.lat]"
      >
        <div class="map__marker"
             :class="{'map__marker--active': coordinate.id === activeCoordinateId}"
             :style="{ backgroundImage: `url(${ coordinate.images[0].src }&size=xs)` }"
             slot="marker"
             tabindex="0"
             @click.stop="onMarkerClick(coordinate, $event)"
        >
          <div class="map__marker__count">
            <span>
              {{ coordinate.images?.length }}
            </span>
          </div>
          <div class="map__marker__street">
            <span>
              {{ coordinate.street?.name }}
            </span>
          </div>
        </div>
      </mgl-marker>
    </mgl-map>
  </div>
</template>

<script>
import Mapbox from 'mapbox-gl';
import { MglMap, MglMarker } from 'vue-mapbox';
import { mapState, mapGetters, mapActions } from 'vuex';
import mapbox from "@/config/mapbox";

let map = {
  fitBoundsCall: null,
  flyToCall: null,

  fitBounds(...options) {
    this.fitBoundsCall = options
  },
  flyTo(...options) {
    this.flyToCall = options
  }
}

export default {
  name: "TheMap",
  components: {
    MglMap,
    MglMarker
  },
  data() {
    return {
      mapbox: null,
      map: null,
      mapConfig: {
        accessToken: mapbox.accessToken,
        style: mapbox.style,
        initialZoom: mapbox.initialZoom
      },
      center: {
        lng: mapbox.center.lng,
        lat: mapbox.center.lat
      },
      fitSquare: [
        [0, 0],
        [0, 0]
      ]
    }
  },
  computed: {
    ...mapState('Coordinates', {
      'storeActiveCoordinateId': 'activeCoordinateId'
    }),
    ...mapGetters('Coordinates', ['coordinates', 'getCoordinateById']),

    activeCoordinateId: {
      get() {
        return this.storeActiveCoordinateId
      },
      set(coordinateId) {
        this.setStoreActiveCoordinateId(coordinateId)
      }
    },
    centerMode() {
      return this.$route.name === 'home'
    }
  },
  created() {
    this.mapbox = Mapbox
  },
  methods: {
    getFitConfig() {
      if (window.innerWidth > 1300) {
        return {
          padding: {
            top: 120,
            right: this.centerMode ? 400 : window.innerWidth - 400,
            bottom: 60,
            left: this.centerMode ? 400 : 0
          },
          maxZoom: mapbox.initialZoom
        }
      } else if (window.innerWidth > 1024) {
        return {
          padding: {
            top: 120,
            right: this.centerMode ? 250 : window.innerWidth - 250,
            bottom: 60,
            left: this.centerMode ? 350 : 0
          },
          maxZoom: mapbox.initialZoom
        }
      } else {
        return {
          padding: {
            top: 60,
            right: window.innerWidth / 7,
            bottom: 60,
            left: window.innerWidth / 7
          },
          maxZoom: mapbox.initialZoom
        }
      }
    },
    onMapLoad(event) {
      let fitBoundsCall = map.fitBoundsCall
      let flyToCall = map.flyToCall

      map = event.map

      if (fitBoundsCall) {
        map.fitBounds(...fitBoundsCall)
      } else if (flyToCall) {
        map.flyTo(...flyToCall)
      }
    },
    recalculateCenter() {
      if (this.coordinates.length === 0) {
        this.center.lng = mapbox.center.lng
        this.center.lat = mapbox.center.lat

        this.fitSquare[0][0] = mapbox.center.lng - 1
        this.fitSquare[0][1] = mapbox.center.lat - 1
        this.fitSquare[1][0] = mapbox.center.lng + 1
        this.fitSquare[1][1] = mapbox.center.lat + 1

        return;
      }

      let lngSum = 0,
        latSum = 0,
        lngMin = this.coordinates[0].lng,
        latMin = this.coordinates[0].lat,
        lngMax = this.coordinates[0].lng,
        latMax = this.coordinates[0].lat;

      this.coordinates.forEach(({ lng, lat }) => {
        lngSum += lng
        latSum += lat

        lngMin = Math.min(lngMin, lng)
        latMin = Math.min(latMin, lat)
        lngMax = Math.max(lngMax, lng)
        latMax = Math.max(latMax, lat)
      })

      this.fitSquare[0][0] = lngMin
      this.fitSquare[0][1] = latMin
      this.fitSquare[1][0] = lngMax
      this.fitSquare[1][1] = latMax

      map.flyTo({
        center: {
          lng: lngSum / this.coordinates.length,
          lat: latSum / this.coordinates.length
        },
        zoom: this.mapConfig.initialZoom,
        speed: .2
      })
    },
    fitMap() {
      map.fitBounds(this.fitSquare, this.getFitConfig())
    },
    zoomToCoordinate(coordinate = this.getCoordinateById(this.activeCoordinateId)) {
      if (this.centerMode) {
        const currentZoom = map.getZoom();
        map.flyTo({
          center: [coordinate.lng, coordinate.lat],
          zoom:
            currentZoom < this.mapConfig.initialZoom ? this.mapConfig.initialZoom : currentZoom,
          speed: .8
        })
      } else {
        map.fitBounds([
          [coordinate.lng, coordinate.lat],
          [coordinate.lng, coordinate.lat]
        ], this.getFitConfig())
      }
    },
    onMarkerClick(coordinate) {
      if (this.activeCoordinateId === coordinate.id) {
        this.$router.push({ name: 'itemGallery', params: { itemId: coordinate.id } })
      } else {
        this.removeActiveCoordinate()
        this.setActiveCoordinate(coordinate)
      }
    },
    setActiveCoordinate(coordinate) {
      this.activeCoordinateId = coordinate.id
    },
    removeActiveCoordinate() {
      this.activeCoordinateId = null
    },

    ...mapActions('Coordinates', {
      'setStoreActiveCoordinateId': 'setActiveCoordinateId'
    })
  },
  watch: {
    coordinates: function () {
      this.recalculateCenter()
      this.fitMap()
    },
    activeCoordinateId: function (coordinateId) {
      if (coordinateId === null) {
        this.fitMap()
      } else {
        this.zoomToCoordinate(this.getCoordinateById(coordinateId))
      }
    },
    centerMode: function () {
      if (this.activeCoordinateId !== null) {
        this.zoomToCoordinate()
      }
    }
  }
}
</script>

<style lang="scss">
.map-container {
  width: 100%;
  height: 100%;
  background-color: rgba(204, 204, 204, 0.78);
}

.map {

  &__marker {
    box-shadow: 0 5px 6px rgba(black, 0.5);
    transition: border-color 0.35s cubic-bezier(0.4, 0, 0.2, 1);
    border: 4px solid #28655F;
    position: absolute;
    border-radius: 50%;
    cursor: pointer;
    height: 76px;
    width: 76px;
    background-color: #FFFFFF;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &--active {
      border-color: #7CB630;
      z-index: 10;
    }

    &--active &__count {
      border-color: #FFFFFF;
      background: #7CB630;
    }

    &--active &__street {
      display: block;
    }

    &__count {
      background: linear-gradient(90deg, #284365 0%, #286536 100%);
      border: 2px solid #FFFFFF;
      justify-content: center;
      align-items: center;
      position: absolute;
      border-radius: 50%;
      color: #FFFFFF;
      line-height: 1;
      display: flex;
      height: 25px;
      width: 25px;
      right: -4px;
      top: -4px;
    }

    &__street {
      display: none;
      position: absolute;
      background: #7CB630;
      color: white;
      height: 20px;
      line-height: 16px;
      padding: 2px 8px;
      border-radius: 10px;
      top: 54px;
      right: 48px;
      font-size: 16px;
      font-weight: 100;
      font-family: "DINCondensed", sans-serif;

      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 120px;
      }
    }

  }

}
</style>
