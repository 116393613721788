export class Filter {

  constructor(data, type) {
    this.id = data.id
    this.name = data.name

    this.type = type

    this.isActive = false
    this.disabled = false
  }

  setIsActiveState(isActive) {
    this.isActive = isActive
  }

}

export class BuilderFilter extends Filter {
  constructor(data) {
    super(data, 'builder');
  }
}

export class EpochFilter extends Filter {
  constructor(data) {
    super({
      id: data.id,
      name: [data.from, data.from].join('-')
    }, 'epoch')

    this.from = data.from
    this.to = data.to
    this.imagesCount = data.images_count
  }
}

export class RegionFilter extends Filter {
  constructor(data) {
    super(data, 'region');
  }
}

export class StreetFilter extends Filter {
  constructor(data) {
    super(data, 'street');
  }
}

export class TypeFilter extends Filter {
  constructor(data) {
    super(data, 'type');
  }
}

export default {
  Filter,
  BuilderFilter,
  EpochFilter,
  RegionFilter,
  StreetFilter,
  TypeFilter
}