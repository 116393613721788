<template>
  <div class="search-box"
       v-click-outside="onClickOutside"
  >
    <app-input v-model="inputValue"
               placeholder="Search"
               @focus="onFocus"
    >
      <template #icon>
        <img src="@/assets/images/icons/magnifier.svg"
             alt="magnifier"
             style="margin-top: 5px;"
        >
      </template>
    </app-input>

    <div v-if="isSearchActive"
         class="search-box__dropdown"
    >
      <button v-for="filter in searchResults"
              :key="`${ filter.type }-${ filter.id }`"
              class="search-box__dropdown-item"
              @click="toggleFilter(filter)"
      >
        {{ filter.name }}
      </button>
    </div>
  </div>
</template>

<script>
import AppInput from "@/components/common/AppInput/AppInput";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "SearchField",
  components: {
    AppInput
  },
  data() {
    return {
      inputValue: '',
      isInFocus: false
    }
  },
  computed: {
    ...mapState('Filters', ['searchQuery']),
    ...mapGetters('Filters', ['allFilters']),

    isSearchActive() {
      return this.inputValue.length > 0 && this.isInFocus
    },
    searchResults() {
      if (this.isSearchActive) {
        return this.allFilters.filter(({ name }) => name.toLowerCase().includes(this.inputValue.toLowerCase()))
      } else {
        return []
      }
    }
  },
  methods: {
    onFocus() {
      this.isInFocus = true
    },
    onClickOutside() {
      this.isInFocus = false
    },
    toggleFilter(filter) {
      this.inputValue = filter.name
      this.setSearchQuery(filter.name)
      this.isInFocus = false
      this.reloadCoordinates()
    },

    ...mapActions('Filters', ['setSearchQuery']),
    ...mapActions('Coordinates', ['reloadCoordinates'])
  },
  watch: {
    inputValue: function (inputValue) {
      if (inputValue === '' && this.searchQuery !== '') {
        this.toggleFilter({ name: '' })
      }
    },
    searchQuery: function (searchQuery) {
      if (searchQuery !== this.inputValue) {
        this.inputValue = searchQuery
      }
    }
  }
}
</script>

<style lang="scss">
.search-box {
  position: relative;
  display: flex;
  margin: 0 9px;

  &__dropdown {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    display: flex;
    flex-direction: column;
    min-width: 100%;
    max-height: 70vh;
    overflow: auto;
    padding: 10px 0;
    background-color: $dropdown-background;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 10px;
      left: 11px;
      display: block;
      height: 1px;
      background-color: $dropdown-separator-background-color;
    }

  }

  &__dropdown-item {
    padding: 9px 10px;
    color: $app-primary-color;
    font-weight: 400;
    background-color: rgba($app-primary-color, 0);
    transition: background-color .2s;
    border: none;
    text-align: left;
    font-size: 1em;
    cursor: pointer;

    &:hover {
      background-color: rgba($app-primary-color, .1);
    }

  }

}
</style>