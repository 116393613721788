<template>
  <div id="app"
       class="app"
  >

    <fullscreen-image/>

    <the-header/>

    <main>

      <the-map/>

      <div class="content">
        <router-view/>
      </div>

      <the-timeline/>

      <mobile-navigator/>

    </main>

  </div>
</template>

<script>
import FullscreenImage from "@/components/common/FullscreenImage/FullscreenImage";
import TheHeader from "@/components/common/TheHeader/TheHeader";
import TheMap from "@/components/common/TheMap/TheMap";
import TheTimeline from "@/components/common/TheTimeline/TheTimeline";
import MobileNavigator from "@/components/common/MobileNavigator/MobileNavigator";

export default {
  name: 'App',
  components: {
    FullscreenImage,
    TheHeader,
    TheMap,
    TheTimeline,
    MobileNavigator
  },
  created() {
    this.$store.dispatch('loadMainApiData')
  }
}
</script>

<style lang="scss">
.app {
  width: 100%;
  height: 100%;

  main {
    position: relative;
    height: calc(100% - $header-height);

    .content {
      position: absolute;
      top: $content-padding-top;
      bottom: $content-padding-bottom;
      left: 0;
      z-index: 20;
    }

  }

}

@media screen and (max-width: 1024px) {
  .app {
    main {
      .content {
        top: 0;
        bottom: 0;
      }
    }
  }
}
</style>
