<template>
  <app-dropdown title="Epoche"
                class="epoch-selector"
                ref="dropdown"
  >
    <div class="epoch-selector__dropdown">
      <epoch-selector-item v-for="epoch in epochs"
                           :key="epoch.id"
                           :filter="epoch"
                           filter-type="epochs"
      />
    </div>

    <selector-controls @action="hideDropdown"/>

    <transition name="fade">
      <div class="epoch-selector__is-loading-overlay"
           v-if="isActiveFiltersLoading"
      >
        <div class="loader"
        />
      </div>
    </transition>
  </app-dropdown>
</template>

<script>
import AppDropdown from "@/components/common/AppDropdown/AppDropdown";
import EpochSelectorItem from "@/components/common/TheHeader/EpochSelector/EpochSelectorItem";
import SelectorControls from "@/components/common/TheHeader/SelectorControls/SelectorControls";
import { mapState } from "vuex";

export default {
  name: "EpochSelector",
  components: {
    AppDropdown,
    EpochSelectorItem,
    SelectorControls
  },
  methods: {
    hideDropdown() {
      this.$refs['dropdown'].hideDropdown()
    }
  },
  computed: {
    ...mapState('Filters', ['epochs', 'isActiveFiltersLoading'])
  }
}
</script>

<style lang="scss">
.epoch-selector {
  position: relative;

  &__dropdown {
    max-height: 70vh;
    overflow-y: auto;
    padding: 15px 0;
    display: flex;
    flex-direction: column;
  }

  &__item {

    &__period {
      color: $app-primary-color;
      font-size: 1.1em;
    }

    &__images-count {
      font-weight: 700;
    }

  }

  .app-checkbox {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 11px 16px 10px 10px;

    &__label {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-left: 10px;
    }

  }

  &__is-loading-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($dropdown-background, .6);
    display: flex;
    align-items: center;
    justify-content: center;

    .loader {
      width: 100px;
      height: 100px;
      border-width: 7px;
    }

  }

}
</style>