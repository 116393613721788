import api from "@/api";
import router from "@/router";
import {
  BuilderFilter,
  EpochFilter,
  RegionFilter,
  StreetFilter,
  TypeFilter
} from "@/models/Filters";
import hasQueryParams from "@/helpers/hasRouteQueryParams";
import parseFiltersFromRoute from "@/helpers/parseFiltersFromRoute";
import filtersApiAdapter from "@/helpers/filtersApiAdapter";

const state = {
  builders: [],
  epochs: [],
  regions: [],
  streets: [],
  types: [],
  searchQuery: '',

  isActiveFiltersLoading: false
}

const getters = {
  allFilters: (state) => {
    return [
      state.builders,
      state.epochs,
      state.regions,
      state.streets,
      state.types
    ].flat()
  },
  activeFilters: (state) => {
    return {
      builders: state.builders.filter(({ isActive }) => isActive).map(({ id }) => id),
      epochs: state.epochs.filter(({ isActive }) => isActive).map(({ id }) => id),
      regions: state.regions.filter(({ isActive }) => isActive).map(({ id }) => id),
      streets: state.streets.filter(({ isActive }) => isActive).map(({ id }) => id),
      types: state.types.filter(({ isActive }) => isActive).map(({ id }) => id)
    }
  },
  apiActiveFilters: (state, getters) => {
    return {
      ...filtersApiAdapter(getters.activeFilters),
      q: state.searchQuery
    }
  },
  hasFilters: (state, getters) => {
    return Object.values(getters.activeFilters).some((filters) => filters.length !== 0) || state.searchQuery !== ''
  }
}

const mutations = {
  SET_IS_ACTIVE_FILTERS_LOADING_STATE(state, isActiveFiltersLoading) {
    state.isActiveFiltersLoading = isActiveFiltersLoading
  },
  SET_BUILDERS(state, builders) {
    state.builders = builders
  },
  SET_EPOCHS(state, epochs) {
    state.epochs = epochs.sort((a, b) => a.to - b.to)
  },
  SET_REGIONS(state, regions) {
    state.regions = regions
  },
  SET_STREETS(state, streets) {
    state.streets = streets
  },
  SET_TYPES(state, types) {
    state.types = types
  },
  SET_FILTER_IS_ACTIVE_STATE(state, { filterType, id, isActive }) {
    state[filterType]
      .find((filter) => filter.id === id)
      .setIsActiveState(isActive)
  },
  SET_FILTER_DISABLED_STATE(state, { filterType, id, disabled }) {
    state[filterType]
      .find((filter) => filter.id === id)
      .disabled = disabled
  },
  RESET_ALL_FILTERS(state) {
    ['builders', 'epochs', 'regions', 'streets', 'types'].forEach((filterType) => {
      state[filterType].forEach((filter) => {
        filter.setIsActiveState(false)
      })
    })
    state.searchQuery = ''
  },
  SET_SEARCH_QUERY(state, searchQuery) {
    state.searchQuery = searchQuery
  },
  SET_EPOCH_IMAGES_COUNT(state, { id, imagesCount }) {
    state.epochs
      .find((epoch) => epoch.id === id)
      .imagesCount = imagesCount
  }
}

const actions = {
  initFilters({ commit }, filters) {
    commit('SET_BUILDERS', filters.builders.map(builder => new BuilderFilter(builder)))
    commit('SET_EPOCHS', filters.epochs.map(epoch => new EpochFilter(epoch)))
    commit('SET_REGIONS', filters.regions.map(region => new RegionFilter(region)))
    commit('SET_STREETS', filters.streets.map(street => new StreetFilter(street)))
    commit('SET_TYPES', filters.types.map(type => new TypeFilter(type)))
  },
  processActiveFiltersData({ state, commit }, filters) {
    (['builders', 'regions', 'streets', 'types']).forEach((filterType) => {
      state[filterType].forEach((filter) => {
        commit('SET_FILTER_DISABLED_STATE', {
          filterType,
          id: filter.id,
          disabled: filters[filterType].every(({ id }) => id !== filter.id)
        })
      })
    })

    filters.epochs.forEach((epoch) => {
      commit('SET_EPOCH_IMAGES_COUNT', {
        id: epoch.id,
        imagesCount: epoch.images_count
      })
    })
  },
  reloadActiveFilters({ getters, dispatch, commit }) {
    commit('SET_IS_ACTIVE_FILTERS_LOADING_STATE', true)
    return api.getByFilter(getters.apiActiveFilters)
      .then(({ data: { data } }) => {
        dispatch('processActiveFiltersData', data.tags)
      })
      .finally(() => {
        commit('SET_IS_ACTIVE_FILTERS_LOADING_STATE', false)
      })
  },
  setFilterIsActiveState({ commit, dispatch }, data) {
    commit('SET_FILTER_IS_ACTIVE_STATE', data)
    dispatch('reloadActiveFilters')
  },
  resetAllFilters({ commit }) {
    commit('RESET_ALL_FILTERS')
  },
  setSearchQuery({ commit }, searchQuery) {
    commit('SET_SEARCH_QUERY', searchQuery)
  },
  processRouteFilters({ getters, dispatch }) {
    const route = router.currentRoute

    if (!getters.hasFilters && hasQueryParams(route)) {
      dispatch('setFiltersFromQuery', parseFiltersFromRoute(route))
    }
  },
  setFiltersFromQuery({ dispatch }, filters) {
    if (filters.searchQuery) {
      dispatch('setSearchQuery', filters.searchQuery)
      delete filters.searchQuery
    }

    Object.keys(filters)
      .forEach((filterType) => {
        filters[filterType].forEach((filterId) => {
          dispatch('setFilterIsActiveState', {
            filterType,
            id: filterId,
            isActive: true
          })
        })
      })

    dispatch('Coordinates/reloadCoordinates', undefined, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}