import { mapActions } from "vuex";

export default {
  props: {
    filter: {
      type: Object,
      required: true
    },
    filterType: {
      type: String,
      required: true,
      validator: (v) => ['builders', 'epochs', 'regions', 'streets', 'types'].includes(v)
    }
  },
  computed: {
    value: {
      get() {
        return this.filter.isActive
      },
      set(value) {
        this.setFilterIsActiveState({
          filterType: this.filterType,
          id: this.filter.id,
          isActive: value
        })
      }
    }
  },
  methods: {
    ...mapActions('Filters', ['setFilterIsActiveState'])
  }
}