export class Image {

  constructor(data, coordinateId) {
    this.builder = data.builder
    this.company = data.company
    this.description = data.description
    this.id = data.id
    this.src = data.src
    this.srcNumber = data.src_number
    this.subImages = data.sub_images
    this.type = data.type
    this.yearOfBuild = data.year_of_build
    this.yearOfRebuild = data.year_of_rebuild

    this.coordinateId = coordinateId

    if (data.epoch) {
      this.epoch = data.epoch
    }
  }

}

export default Image