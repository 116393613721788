<template>
  <app-checkbox v-model="value"
                class="epoch-selector__item"
                :disabled="isDisabled"
  >
    <span class="epoch-selector__item__period">{{ epoch.from }} - {{ epoch.to }}</span>
    <span class="epoch-selector__item__images-count">{{ epoch.imagesCount }} bld.</span>
  </app-checkbox>
</template>

<script>
import filterMixin from "@/mixins/filter";
import AppCheckbox from "@/components/common/AppCheckbox/AppCheckbox";

export default {
  name: "EpochSelectorItem",
  mixins: [filterMixin],
  components: {
    AppCheckbox
  },
  computed: {
    epoch() {
      return this.filter
    },
    isDisabled() {
      return this.epoch.imagesCount === 0
    }
  }
}
</script>