<template>
  <label class="app-input">
    <input v-model="inputValue"
           type="text"
           class="app-input__input"
           :placeholder="placeholder"
           @focus="$emit('focus', $event)"
    >

    <span class="app-input__icon">
      <slot name="icon"></slot>
    </span>
  </label>
</template>

<script>
export default {
  name: "AppInput",
  props: {
    value: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss">
.app-input {
  position: relative;
  width: 200px;
  height: 58px;
  background-color: $input-background-color;
  padding: 10px;

  &__input {
    font-family: "DIN Condensed", sans-serif;
    font-size: 2.5em;
    font-weight: 700;
    color: $input-color;
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    vertical-align: center;
    padding-top: 15px;

    &::placeholder {
      color: $input-placeholder-color;
    }

  }

  &__icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

}

@media screen and (max-width: 1400px) {
  .app-input {
    width: 180px;

    &__input {
      font-size: 2em;
    }
  }
}


@media screen and (max-width: 1024px) {
  .app-input {
    width: 100%;
  }
}
</style>
