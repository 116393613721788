import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex)

import Coordinates from "@/store/modules/Coordinates";
import Filters from "@/store/modules/Filters";

import actions from "@/store/actions";

const store = new Vuex.Store({

  modules: {
    Coordinates,
    Filters
  },

  actions

})

export default store
