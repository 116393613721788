import Vue from "vue";
import VueRouter from "vue-router";
import hasQueryParams from "@/helpers/hasRouteQueryParams";

import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  routes
})

router.beforeEach((to, from, next) => {
  if (!hasQueryParams(to) && hasQueryParams(from) && window.location.search !== '') {
    next({ name: to.name, query: from.query })
  } else {
    next()
  }
})

export default router
