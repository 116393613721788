<template>
  <div class="timeline"
       :class="{'timeline--mobile-active': mobileActive}"
  >
    <div class="timeline__head">
      <p class="timeline__title">Zeitleiste</p>
    </div>

    <div class="timeline__content">
      <timeline-images/>

      <div class="timeline__decades hide-scrollbar">
        <timeline-decade v-for="decade in decades"
                         :key="decade.decade"
                         :decade="decade"
        />
      </div>
    </div>

    <img src="@/assets/images/timeline-tree.svg"
         alt="timeline tree"
         class="timeline__background-image"
    >

    <button class="timeline__label"
            @click="mobileActive = !mobileActive"
    >
      <img v-if="mobileActive"
           src="@/assets/images/icons/arrow-right-double_green.svg"
           alt="timeline label"
      >
      <img v-else
           src="@/assets/images/icons/timeline-label.svg"
           alt="timeline label"
      >
    </button>
  </div>
</template>

<script>
import TimelineImages from "@/components/common/TheTimeline/TimelineImages";
import TimelineDecade from "@/components/common/TheTimeline/TimelineDecade";
import timelineConfig from "@/config/timeline";
import getDecades from "@/helpers/getDecades";

export default {
  name: "TheTimeline",
  components: {
    TimelineImages,
    TimelineDecade
  },
  data() {
    return {
      config: timelineConfig,
      decades: getDecades(timelineConfig.minYear, timelineConfig.maxYear),
      mobileActive: false
    }
  },
  watch: {
    $route: {
      deep: true,
      handler: function () {
        this.mobileActive = false
      }
    }
  }
}
</script>

<style lang="scss">
.timeline {
  position: absolute;
  top: $content-padding-top;
  bottom: $content-padding-bottom;
  right: 0;
  display: flex;
  flex-direction: column;
  width: $timeline-width;
  padding: 13px 30px 13px 20px;
  background-color: $app-block-color;
  box-shadow: 0 2px 4px $app-box-shadow-color;
  z-index: 20;
  overflow: hidden;

  &__head {
    color: $app-text-color;
    padding-bottom: 13px;
    border-bottom: 1px solid $app-text-color;
  }

  &__title {
    font-size: 2em;
    font-weight: 500;
    text-transform: uppercase;
    text-align: right;
  }

  &__content {
    max-height: 100%;
  }

  &__decades {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    padding-top: 40px;
    max-height: 100%;
    z-index: 1;
  }

  &__background-image {
    position: absolute;
    right: -40%;
    bottom: 10%;
    height: 60%;
    width: 100%;
    z-index: -1;
  }

  &__label {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 0;
    width: 66px;
    height: 66px;
    border: none;
    background-color: $app-block-color;
    box-shadow: 0 2px 4px $app-box-shadow-color;
    cursor: pointer;
    z-index: 20;
  }

}

@media screen and (max-width: 1300px) {
  .timeline {
    width: 200px;

    &__title {
      font-size: 1.5em;
    }
  }
}

@media screen and (max-width: 1024px) {
  .timeline {
    right: -100%;
    top: 0;
    bottom: 0;
    width: 100%;
    transition: .3s;
    
    &__head {
      display: none;
    }

    &__content {
      display: grid;
      grid-template-columns: 50% 50%;
    }

    &__label {
      display: block;
    }

    &--mobile-active {
      right: 0;
    }
  }
}
</style>
