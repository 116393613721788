<template>
  <app-dropdown title="Tags"
                class="tags-selector"
                ref="dropdown"
  >
    <div class="tags-selector__dropdown">
      <tags-selector-list title="Strasse"
                          :filters="streets"
                          filter-type="streets"
      />
      <tags-selector-list title="Ortsteil"
                          :filters="regions"
                          filter-type="regions"
      />
      <tags-selector-list title="Attribut"
                          :filters="types"
                          filter-type="types"
      />
      <tags-selector-list title="Thema"
                          :filters="builders"
                          filter-type="builders"
      />
    </div>

    <selector-controls @action="hideDropdown"/>

    <transition name="fade">
      <div class="tags-selector__is-loading-overlay"
           v-if="isActiveFiltersLoading"
      >
        <div class="loader"
        />
      </div>
    </transition>
  </app-dropdown>
</template>

<script>
import AppDropdown from "@/components/common/AppDropdown/AppDropdown";
import TagsSelectorList from "@/components/common/TheHeader/TagsSelector/TagsSelectorList";
import SelectorControls from "@/components/common/TheHeader/SelectorControls/SelectorControls";
import { mapState } from "vuex";

export default {
  name: "TagsSelector",
  components: {
    AppDropdown,
    TagsSelectorList,
    SelectorControls
  },
  methods: {
    hideDropdown() {
      this.$refs['dropdown'].hideDropdown()
    }
  },
  computed: {
    ...mapState('Filters', ['streets', 'regions', 'types', 'builders', 'isActiveFiltersLoading'])
  }
}
</script>

<style lang="scss">
.tags-selector {
  position: relative;

  &__dropdown {
    max-height: 70vh;
    overflow-y: auto;
    padding: 22px 18px 28px 21px;
  }

  &__list {
    width: 560px;
    margin-bottom: 28px;

    &__title {
      font-size: 2.1em;
      font-weight: 500;
      margin-bottom: 12px;
    }

  }

  &__is-loading-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($dropdown-background, .6);
    display: flex;
    align-items: center;
    justify-content: center;

    .loader {
      width: 100px;
      height: 100px;
      border-width: 7px;
    }

  }

}
</style>