import hasQueryParams from "@/helpers/hasRouteQueryParams";

/**
 *
 * @param {Route} route
 *
 * @returns {Object}
 */
export default function parseFiltersFromRoute(route) {
  let filters = {}

  if (hasQueryParams(route)) {
    filters = {
      builders: [route.query['builders[]']].flat(),
      epochs: [route.query['epochs[]']].flat(),
      regions: [route.query['regions[]']].flat(),
      streets: [route.query['streets[]']].flat(),
      types: [route.query['types[]']].flat(),
      searchQuery: route.query['q']
    }

    Object.keys(filters)
      .forEach((filter) => {
        if (filters[filter]?.[0] === undefined) {
          delete filters[filter]
        } else if (typeof filters[filter] === 'object' && Array.isArray(filters[filter])) {
          filters[filter] = filters[filter].map((id) => Number(id))
        }
      })
  }

  return filters
}