import Image from "@/models/Image";

export class Coordinate {

  constructor(data) {
    this.id = data.id
    this.images = data.images.map((image) => new Image(image, data.id))
    this.lat = data.lat
    this.lng = data.lng
    this.num = data.num
    this.region = data.region
    this.street = data.street
  }

  get titleImage() {
    return this.images[0] || null
  }

}

export default Coordinate